<template>
  <h3>{{ slogan }}</h3>
  <el-carousel height="360px">
    <el-carousel-item v-for="(item, index) in items" :key="index">
      <img :src="item.image" alt="sre career target" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      items: [
        { image: "/sre_abi1.png" },
        { image: "/sre_abi2.png" },
        { image: "/sre_abi3.png" },
      ],
    };
  },
  props: {
    slogan: String,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
