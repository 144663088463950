import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TermView from "../views/TermView.vue";
import BasicView from "../views/BasicView.vue";
import AdvancedView from "../views/AdvancedView.vue";
import CloudCompute from "../views/CloudCompute.vue";
import OnlineDistros from "../views/OnlineDistros.vue";
import PortalHome from "../views/PortalHome.vue";
import LoginPage from "../views/LoginPage.vue";
import RegPage from "../views/RegPage.vue";
import SpecialSkills from "../views/SpecialSkills.vue";
import DemoPage from "../views/DemoPage.vue";
import GoodOps from "../views/GoodOps.vue";
import BuyVip from "../views/BuyVip.vue";
import UserPrivacy from "../views/UserPrivacy.vue";
import SREEngineering from "../views/SREEngineering.vue";
import HotWordsView from "../views/HotWordsView.vue";
import UserCenter from "../views/UserCenter.vue";
import FAQ from "../views/FAQ.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/term",
    name: "term",
    component: TermView,
  },
  {
    path: "/cloudcompute",
    name: "cloudcompute",
    component: CloudCompute,
  },
  {
    path: "/basic",
    name: "basic",
    component: BasicView,
  },
  {
    path: "/advanced",
    name: "advanced",
    component: AdvancedView,
  },
  {
    path: "/hotwords",
    name: "hotwords",
    component: HotWordsView,
  },
  {
    path: "/distros",
    name: "distros",
    component: OnlineDistros,
  },
  {
    path: "/goodops",
    name: "goodops",
    component: GoodOps,
  },
  {
    path: "/sreengineering",
    name: "sreengineering",
    component: SREEngineering,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/portal",
    name: "portal",
    component: PortalHome,
  },
  {
    path: "/topics",
    name: "topics",
    component: SpecialSkills,
  },
  {
    path: "/user-center",
    name: "user-center",
    component: UserCenter,
  },
  {
    path: "/buyvip",
    name: "buyvip",
    component: BuyVip,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "register",
    component: RegPage,
  },
  {
    path: "/demo",
    name: "demo",
    component: DemoPage,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: UserPrivacy,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
