<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="220px">
        <el-space wrap>
          <el-text
            style="display: block; font-weight: 600; padding: 1rem 1.2rem 1rem"
            >目录</el-text
          >
          <el-link
            type="default"
            style="display: block"
            class="subtitle"
            @click="activeComp = 'CmdDf'"
            >df命令的3个重要参数
          </el-link>
          <el-link
            type="default"
            style="display: block"
            class="subtitle"
            @click="activeComp = 'CmdLs'"
            >ls -a 查看隐藏文件
          </el-link>
          <el-link
            type="default"
            style="display: block"
            class="subtitle"
            @click="activeComp = 'CmdRm'"
            >rm 命令删除文件
          </el-link>
          <el-link
            type="default"
            style="display: block"
            class="subtitle"
            @click="activeComp = 'CmdRm'"
            >rm -r 命令删除目录
          </el-link>
          <el-link type="default" style="display: block" class="subtitle"
            >who -b查看上次重启时间</el-link
          >
          <el-link type="default" style="display: block" class="subtitle"
            >uptime查看系统运行多久了</el-link
          >
          <el-link type="default" style="display: block" class="subtitle"
            >使用wget -c下载软件包</el-link
          >
          <el-link type="default" style="display: block" class="subtitle"
            >使用curl 下载软件包</el-link
          >
          <el-link type="default" style="display: block" class="subtitle"
            >使用curl 发送HTTP请求</el-link
          >
          <el-link type="default" style="display: block" class="subtitle"
            >使用ss -plunt检查端口占用</el-link
          >
        </el-space>
      </el-aside>
      <el-main class="line">
        <component :is="activeComp"></component>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import CmdLs from "./subview/l/CmdLs.vue";
import CmdRm from "./subview/r/CmdRm.vue";

export default {
  name: "BasicView",
  data() {
    return {
      activeComp: "",
    };
  },
  components: {
    CmdLs,
    CmdRm,
  },
};
</script>
<style scoped>
.line {
  border-left: 1px solid #bdbdbd;
  height: 100vh;
  left: 220px;
}
.subtitle {
  padding: 0.34rem 1.2rem;
  font-weight: 400;
}

.subtitle:hover {
  background-color: #aed581;
  text-decoration: none;
  color: black;
}
</style>
