<template>
  <el-card>
    <div>
      <span>词汇展示</span>
    </div>

    <div>
      <!-- 词 -->
      <h3>{{ word }}</h3>

      <!-- 解释 -->
      <p v-html="formattedExplanation"></p>

      <!-- 示例句子 -->
      <p><strong>示例句子:</strong> {{ exampleSentence }}</p>
    </div>
  </el-card>
</template>

<script>
import { ElCard } from "element-plus";

export default {
  name: "CloudCompute",
  components: {
    ElCard,
  },
  data() {
    return {
      word: "示例词",
      explanation: "这是一个示例词的解释。<br> Next line.",
      exampleSentence: "这是一个示例句子，用来演示如何展示词汇的示例。",
    };
  },
  computed: {
    formattedExplanation() {
      // 使用 v-html 渲染包含 <br> 标签的文本
      return this.explanation.replace(/\n/g, "<br>");
    },
  },
};
</script>

<style scoped>
/* 可以根据需要添加样式 */
</style>
