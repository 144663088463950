<template>
  <el-container>
    <div class="connect">
      <el-button type="primary" @click="connectBox">连接环境</el-button>
      <el-button
        type="success"
        :disabled="!check_button_enabled"
        @click="submitBox"
        ><el-icon style="margin-right: 4px"><Finished /></el-icon
        >提交本次任务</el-button
      >
    </div>
    <div class="termtitle">
      <span class="indicator">⬤</span>
      <span id="termname">SSH Terminal</span>
    </div>
    <div class="realiframe">
      <iframe :src="labos"> </iframe>
    </div>
  </el-container>
</template>

<script>
import { Finished } from "@element-plus/icons-vue";
export default {
  name: "BigTerms",
  data() {
    return {
      labos: "",
      check_button_enabled: false,
    };
  },
  props: ["thost", "whost", "wport", "port", "lname", "passwd"],
  methods: {
    connectBox() {
      this.labos = this.start_task();
      this.check_button_enabled = true;
      console.log(this.labos);
    },
    submitBox() {
      this.labos = this.check_task();
      console.log(this.labos);
    },
    enable_check_button() {
      this.check_button_enabled = true;
    },
    start_task() {
      return (this.labos =
        "http://" +
        this.whost +
        ":" +
        this.wport +
        "/?hostname=" +
        this.thost +
        "&username=" +
        this.lname +
        "&port=" +
        this.port +
        "&password=" +
        this.passwd);
    },
    check_task() {
      return (this.labos =
        "http://" +
        this.whost +
        ":" +
        this.wport +
        "/?hostname=" +
        this.thost +
        "&username=" +
        this.lname +
        "&port=" +
        this.port +
        "&password=" +
        this.passwd);
    },
  },
  components: {
    Finished,
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.el-button {
  margin: 4px 12px;
}
.connect {
  margin: 12px 0;
  align-items: left;
  display: flex;
  justify-content: flex-start;
}
iframe {
  height: 600px;
  width: 800px;
  border: 2px solid #263238;
  box-sizing: border-box;
}
.termtitle {
  background-color: #263238;
  height: 1.48rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 800px;
  text-align: left;
}
.indicator {
  color: #00e676;
  padding: 0.4rem;
}

#termname {
  color: white;
  font-size: 14px;
}
</style>
