<template>
  <div class="game-score-display">
    <div class="score-container">
      <span>通关积分: </span>
      <span class="score-number"> {{ score }}</span>
      <div class="score-background"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameScoreDisplay",
  props: {
    score: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style scoped>
.game-score-display {
  position: relative;
  display: inline-block;
  /* 你可以添加更多样式以调整位置和大小 */
}

.score-container {
  padding: 6px 12px;
  border-radius: 6px;
  margin-left: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(180, 255, 180, 0.6); /* 背景颜色，可以根据需要调整 */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  /* 你可以添加过渡效果以使其看起来更炫酷 */
  transition: background-color 0.3s ease;
}

.score-number {
  font-size: 20px;
  font-weight: bold;
  color: #333; /* 文本颜色，可以根据需要调整 */
  /* 你可以添加文本效果如动画或渐变 */
}

.score-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 0, 0, 0.1),
    rgba(255, 0, 0, 0.1) 10px,
    rgba(0, 255, 0, 0.1) 10px,
    rgba(0, 255, 0, 0.1) 20px
  ); /* 炫酷的背景效果，可以根据需要调整 */
  opacity: 0.5;
  /* 你可以添加动画效果以使其看起来更生动 */
}
</style>
