<template>
  <div class="medal-wrapper" :class="`medal-${type}`">
    <span class="medal-text">{{ typeText }}</span>
  </div>
</template>

<script>
export default {
  name: "SalaryMedal",
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return [1, 2, 3].includes(value);
      },
    },
  },
  computed: {
    typeText() {
      switch (this.type) {
        case "1":
          return "薪资: 30k+";
        case "2":
          return "薪资: 20k+";
        case "3":
          return "薪资: 10k+";
        default:
          return "薪资: 10k+";
      }
    },
  },
};
</script>

<style scoped>
.medal-wrapper {
  padding: 4px 6px;
  font-size: 13px;
  border-radius: 0.4rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  margin-left: 1rem;
}
</style>
