<template>
  <div style="margin: 2rem">
    <el-input
      v-model="text"
      type="textarea"
      :autosize="{ minRows: 5 }"
      placeholder="输入文本"
      clearable
    ></el-input>

    <div style="margin: 1rem 0 1rem 0" class="button-container">
      <el-button type="primary">解析</el-button>
      <el-button type="primary" @click="copyText"
        ><el-icon> <document-copy /> </el-icon>
        <span style="margin-left: 4px">Copy</span></el-button
      >
      <el-button>清空</el-button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { DocumentCopy } from "@element-plus/icons-vue";

export default {
  data() {
    return {
      text: "",
    };
  },
  components: {
    DocumentCopy,
  },
  methods: {
    copyText() {
      navigator.clipboard
        .writeText(this.text)
        .then(() => {
          ElMessage({
            message: "已复制",
            type: "success",
          });
        })
        .catch((err) => {
          ElMessage({
            message: "复制失败，请重试。",
            type: "error",
          });
          console.error("复制失败:", err);
        });
    },
  },
};
</script>

<style scoped>
.el-button {
  margin-left: 8px;
  padding: 5px 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
</style>
