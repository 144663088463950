<template>
  <div class="userPrivacy">
    <h4>【隐私策略】</h4>
    <p>
      网络时代，我们（sre.azlinux.com）深知用户隐私的重要性，因此，我们制定了以下隐私策略，以确保用户的个人信息得到妥善保护。
    </p>
    <p style="font-weight: bold">一、信息收集</p>
    <p>
      用户（您）在我们的网站sre.azlinux.com注册时，需要提供电子邮箱地址作为登录和访问本网站资源的唯一方式。
    </p>
    <p>除此之外，我们不会要求或收集用户的任何其他个人信息。</p>
    <p style="font-weight: bold">二、信息使用</p>
    <p>
      用户提供的电子邮箱地址主要用于发送网站相关的通知，包括但不限于注册确认、账户安全信息、服务条例更新等。
    </p>
    <p>
      我们不会将用户的电子邮箱地址出售或提供给任何第三方，除非得到用户的明确同意或法律另有规定。
    </p>
    <p style="font-weight: bold">三、信息安全</p>
    <p>
      我们采取了一系列安全措施来保护用户的个人信息，包括但不限于数据加密、访问控制等。我们致力于防止数据泄露、丢失或被非法访问。
    </p>
    <p style="font-weight: bold">四、隐私策略更新</p>
    <p>我们可能会根据业务发展和法律变化对隐私策略进行更新。</p>
    <p>
      任何重大更改都会通过网站公告或电子邮件通知用户，本页面也会记录所有版本的【隐私策略】。
    </p>
    <p style="font-weight: bold">五、用户权利</p>
    <p>
      用户有权要求我们删除其提供的个人信息。用户可以通过网站提供的联系方式或电子邮箱地址与我们联系，提出删除请求。我们将在核实用户身份后，0-48小时内，尽快处理完成用户请求。
    </p>
    <p style="font-weight: bold">六、联系方式</p>
    <p>
      如果用户对我们的隐私策略有任何疑问或建议，可以通过以下方式与我们联系：
    </p>
    <p>邮箱：azlinux@azlinux.com</p>
    <p>--</p>
    <p style="font-weight: bold">
      我们承诺严格遵守法律法规，尊重并保护用户的隐私权。
    </p>
    <p style="font-weight: bold">
      感谢用户对我们网站的支持与信任，我们将竭诚为用户提供优质的服务。
    </p>
    <p style="font-weight: bold">本隐私策略自发布之日起生效。</p>
    <p>--</p>
    <p>更新日期: 2024-03-01</p>
    <p>当前版本: v1.0.1</p>
  </div>
</template>
<script>
export default {
  name: "UserPrivacy",
};
</script>
<style scoped>
.userPrivacy {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}
p {
  font-size: 13px;
  text-align: left;
}
</style>
