<template>
  <div class="topsay">
    <el-text tag="mark" size="large">当前为试运行阶段，请勿购买过多。</el-text>
    <p class="acc">当前账号: {{ activeUser }}</p>
  </div>
  <div class="membership-cards">
    <el-card
      class="membership-card"
      :body-style="{ padding: '20px' }"
      :style="{ backgroundColor: 'lightblue' }"
    >
      <p class="card-header">月度会员</p>
      <p>价格：¥58</p>
      <el-button type="primary" @click="purchase('month')">购买1个月</el-button>
    </el-card>
    <el-card
      class="membership-card"
      :body-style="{ padding: '20px' }"
      :style="{ backgroundColor: 'lightyellow' }"
    >
      <p class="card-header">季度会员</p>
      <p>价格：¥158</p>
      <el-button type="primary" @click="purchase('season')"
        >购买1个季度</el-button
      >
    </el-card>
    <el-card
      class="membership-card"
      :body-style="{ padding: '20px' }"
      :style="{ backgroundColor: 'gold' }"
    >
      <p class="card-header">半年会员</p>
      <p>价格：¥0000</p>
      <el-button type="primary" @click="purchase('halfYear')"
        >购买半年</el-button
      >
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeUser: "admin@systemcool.com",
    };
  },
  methods: {
    purchase(membershipType) {
      console.log(membershipType);
    },
  },
};
</script>

<style scoped>
.topsay {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

p.acc {
  text-align: left;
  font-size: 16px;
  padding: 0 8rem;
}

.el-text {
  padding: 0.6rem;
}
.membership-cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 6rem;
}

.membership-card {
  width: 300px;
}

.el-card {
  border-radius: 0.6rem;
}
</style>
