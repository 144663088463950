<template>
  <TaskBoxLeftLine
    task="应用服务器因配置不当被入侵，经杀毒软件查杀，依然有残留文件，文件名特征为: /var/log/.system.data+两位数，（安全部门已取样），请人工全部清理掉。"
    source="告警中心 (Alarm Center)"
  />
  <div style="display: flex; flex-direction: row">
    <RateBox :difficulty="3" :popularity="5" />
    <GameScoreDisplay score="20" />
    <SalaryMedal type="3" />
  </div>
  <div>
    <KnowledgeCard
      subtitle="ls -a 的用法知识点"
      content="ls: “list”的缩写，用于列出目录中的文件和子目录。
-a：“all”的缩写，表示列出所有文件和目录，包括以点（.）开头的隐藏文件和目录。
以点（.）开头的文件和目录通常被认为是隐藏的。
使用cd进入目录/var/log/, 然后ls -a找出提示的隐藏文件，再继续使用rm命令将其删除即可。
    "
    />
    <KnowledgeCard
      subtitle="rm 删除文件知识点"
      content="使用-i这个选项，在删除每个文件之前要求您进行确认。虽然这会降低删除操作的速度，但它可以防止您不小心删除重要文件
避免使用-f选项：-f选项会强制删除文件，而不会提示用户确认。这可能会导致您不小心删除重要文件。
在生产环境中，应该避免使用-f这个选项。
使用-v选项：这个选项会显示被删除的文件名。
避免在根目录（/）下使用rm命令：根目录是Linux系统的核心部分，在根目录下使用rm命令可能会导致系统崩溃或无法启动。    
使用别名，例如alias rm='rm -i'
使用rm命令之前，注意稍微停留，检查下参数，尤其是正则表达式的带有星号(*)的。
"
    />
  </div>
  <div>
    <el-button type="primary" @click="sendRequest">准备环境</el-button>
  </div>
  <BigTerms
    :whost="whost"
    :thost="thost"
    :wport="wport"
    :port="port"
    :lname="lname"
    :passwd="passwd"
  />
</template>

<script>
import axios from "axios";

import TaskBoxLeftLine from "../../base/TaskBoxLeftLine.vue";
import RateBox from "../../base/RateBox.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import BigTerms from "../../base/BigTerms.vue";

export default {
  name: "CmdRm",
  components: {
    RateBox,
    BigTerms,
    TaskBoxLeftLine,
    SalaryMedal,
    GameScoreDisplay,
    KnowledgeCard,
  },
  data() {
    return {
      responseData: null,
      whost: "sre.azlinux.com",
      wport: 8858,
      thost: "sre.azlinux.com",
      port: 22,
      lname: "sre",
      passwd: "YXpMaW51eC5jb20zCg==",
    };
  },
  methods: {
    sendRequest() {
      let baseUrl = "http://sre.azlinux.com:5000/get_server?";
      let id = 10061;
      let url = baseUrl + "id=" + id;
      axios
        .get(url)
        .then((response) => {
          this.responseData = response.data;
          this.whost = this.responseData.data.whost;
          this.wport = this.responseData.data.wport;
          this.thost = this.responseData.data.thost;
          this.port = this.responseData.data.port;
          this.lname = this.responseData.data.username;
          this.passwd = this.responseData.data.passwd;
          console.log(this.responseData.status_code);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
