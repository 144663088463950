<template>
  <div class="term" style="margin: 0.2rem">
    <el-input v-model="usercmd" placeholder="sre@azlinux-dev:~$ 按下Enter执行命令" />
    <div style="padding: 0.2rem">
      <el-text style="display: block; text-align: left; margin-top: 0.2rem">
        标准输出
      </el-text>
      <pre class="std_output">{{ std_output }}</pre>
      <el-text style="display: block; text-align: left"> 错误输出 </el-text>
      <pre class="std_err_output">{{ std_err_output }}</pre>
    </div>
  </div>
</template>
<script>
export default {
  name: "TermView",
  data() {
    return {
      usercmd: "",
      std_output: "/home/sre",
      std_err_output: "",
    };
  },
};
</script>

<style scoped>
.term {
  background-color: #bcaaa4;
}

.std_output {
  min-height: 2rem;
  background-color: black;
  border-radius: 0.32rem;
  color: white;
  display: block;
  margin: 0.6rem;
  padding: 0.4rem;
  text-align: left;
}

.std_err_output {
  min-height: 2rem;
  background-color: black;
  border-radius: 0.32rem;
  color: white;
  display: block;
  margin: 0.6rem;
  padding: 0.4rem;
}
</style>
