<template>
  <el-row class="letter-nav">
    <el-button
      size="large"
      v-for="letter in 'abcdefghijklmn'"
      :key="letter"
      @click="
        () => {
          activeComp = 'Hot' + letter;
        }
      "
      >{{ letter }}</el-button
    >
  </el-row>
  <el-row class="letter-nav">
    <el-button
      size="large"
      v-for="letter in 'opqrstuvwxyz'"
      :key="letter"
      @click="activeComp = 'Hot' + letter"
      >{{ letter }}</el-button
    >
  </el-row>
  <el-row class="letter-nav">
    <el-button
      size="large"
      v-for="letter in '0123456789'"
      :key="letter"
      @click="activeComp = 'HotNo' + letter"
      >{{ letter }}</el-button
    >
  </el-row>
  <el-divider />
  <component :is="activeComp"></component>
</template>

<script>
import Hota from "./hotwords/HotA.vue";
import Hotb from "./hotwords/HotB.vue";
import Hotc from "./hotwords/HotC.vue";
import Hotd from "./hotwords/HotD.vue";
import Hote from "./hotwords/HotE.vue";
import Hotf from "./hotwords/HotF.vue";
import Hotg from "./hotwords/HotG.vue";
import Hoth from "./hotwords/HotH.vue";
import Hoti from "./hotwords/HotI.vue";
import Hotj from "./hotwords/HotJ.vue";
import Hotk from "./hotwords/HotK.vue";
import Hotl from "./hotwords/HotL.vue";
import Hotm from "./hotwords/HotM.vue";
import Hotn from "./hotwords/HotN.vue";
import Hoto from "./hotwords/HotO.vue";
import Hotp from "./hotwords/HotP.vue";
import Hotq from "./hotwords/HotQ.vue";
import Hotr from "./hotwords/HotR.vue";
import Hots from "./hotwords/HotS.vue";
import Hott from "./hotwords/HotT.vue";
import Hotu from "./hotwords/HotU.vue";
import Hotv from "./hotwords/HotV.vue";
import Hotw from "./hotwords/HotW.vue";
import Hotx from "./hotwords/HotX.vue";
import Hoty from "./hotwords/HotY.vue";
import Hotz from "./hotwords/HotZ.vue";
import HotNo0 from "./hotwords/HotNo0.vue";
import HotNo1 from "./hotwords/HotNo1.vue";
import HotNo2 from "./hotwords/HotNo2.vue";
import HotNo3 from "./hotwords/HotNo3.vue";
import HotNo4 from "./hotwords/HotNo4.vue";
import HotNo5 from "./hotwords/HotNo5.vue";
import HotNo6 from "./hotwords/HotNo6.vue";
import HotNo7 from "./hotwords/HotNo7.vue";
import HotNo8 from "./hotwords/HotNo8.vue";
import HotNo9 from "./hotwords/HotNo9.vue";

export default {
  components: {
    Hota,
    Hotb,
    Hotc,
    Hotd,
    Hote,
    Hotf,
    Hotg,
    Hoth,
    Hoti,
    Hotj,
    Hotk,
    Hotl,
    Hotm,
    Hotn,
    Hoto,
    Hotp,
    Hotq,
    Hotr,
    Hots,
    Hott,
    Hotu,
    Hotv,
    Hotw,
    Hotx,
    Hoty,
    Hotz,
    HotNo0,
    HotNo1,
    HotNo2,
    HotNo3,
    HotNo4,
    HotNo5,
    HotNo6,
    HotNo7,
    HotNo8,
    HotNo9,
  },
  data() {
    return {
      activeComp: "HotA", //load the default component
    };
  },
};
</script>

<style scoped>
.terminology-item {
  padding: 0 4rem;
  font-size: 14px;
}
p {
  text-align: left;
}

.letter-nav {
  margin: 1rem 6rem;
}

.el-col {
  border-bottom: 1px solid coral;
}

.el-button {
  border-bottom: 2px solid burlywood;
}
</style>
