<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6" v-for="t in topics" :key="t.name">
        <el-card class="box-card" style="margin: 0.8rem">
          <img :src="t.imgSrc" class="image" />
          <div class="card-header">
            <span style="font-weight: 800">{{ t.name }}</span>
          </div>
          <div class="text-item">
            {{ t.intro }}
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "SpecialSkills",
  data() {
    return {
      topics: [
        {
          name: "Nginx",
          imgSrc: "/topics/nginx_logo.png",
          intro: "高性能的HTTP和反向代理服务器，工程师必会",
        },
        {
          name: "Redis",
          imgSrc: "/topics/redis_logo.png",
          intro: "流行的高性能内存中键值存储(key-value)系统",
        },
        {
          name: "Zookeeper",
          imgSrc: "/topics/zookeeper_logo.png",
          intro: "a high-performance web server and reverse proxy server",
        },
        {
          name: "Bash",
          imgSrc: "/topics/bash_logo.png",
          intro: "Linux发行版里标准的命令解释器, 脚本编写必备",
        },
        {
          name: "Git",
          imgSrc: "/topics/git_logo.png",
          intro: "广泛使用的分布式版本控制系统，主要指git命令",
        },
        {
          name: "Github",
          imgSrc: "/topics/github_logo.png",
          intro: "互联网软件开发的重要网站之一，版本控制、代码托管和分享平台",
        },
        {
          name: "RabbitMQ",
          imgSrc: "/topics/rabbitmq_logo.png",
          intro: "高效可靠的消息队列中间件",
        },
        {
          name: "Docker",
          imgSrc: "/topics/docker_logo.png",
          intro: "经典的容器，标准的进程运行环境，快速发放应用",
        },
        {
          name: "Podman",
          imgSrc: "/topics/podman_logo.png",
          intro: "不需要root权限即可运行的容器",
        },
        {
          name: "Kubernetes",
          imgSrc: "/topics/kubernetes_logo.png",
          intro: "管理容器化应用程序的开源平台",
        },
        {
          name: "Minio",
          imgSrc: "/topics/minio_logo.png",
          intro: "提供高性能、与S3兼容的对象存储系统，使用和部署非常简单",
        },
        {
          name: "Markdown",
          imgSrc: "/topics/markdown_logo.png",
          intro:
            "十分简单的标记语言，让人们更多地关注写文档的内容本身，最后渲染成HTML",
        },
        {
          name: "Kafka",
          imgSrc: "/topics/kafka_logo.png",
          intro: "流处理平台，提供消息的订阅与发布的消息队列",
        },
        {
          name: "Zabbix",
          imgSrc: "/topics/zabbix_logo.png",
          intro: "经典监控工具",
        },
        {
          name: "Grafana",
          imgSrc: "/topics/grafana_logo.png",
          intro: "监控数据的可视化图形展示平台",
        },
        {
          name: "Keepalived",
          imgSrc: "/topics/keepalived_logo.png",
          intro: "虚拟路由冗余协议来实现服务或者网络的高可用",
        },
        {
          name: "HAProxy",
          imgSrc: "/topics/haproxy_logo.png",
          intro: "高性能TCP和HTTP负载均衡",
        },
        {
          name: "KVM",
          imgSrc: "/topics/kvm_logo.png",
          intro: "基于内核的虚拟机（KVM）",
        },
        {
          name: "confluence",
          imgSrc: "/topics/confluence_logo.png",
          intro:
            "专业的团队知识管理与协同软件，成员之间共享信息、文档协作、集体讨论，信息推送。",
        },
        {
          name: "prometheus",
          imgSrc: "/topics/prometheus_logo.png",
          intro: "基于时序数据库的开源监控告警系统，监控后起之秀",
        },
        {
          name: "etcd",
          imgSrc: "/topics/etcd_logo.png",
          intro: "分布式键值对存储，可靠而快速的保存关键数据并提供访问",
        },
      ],
    };
  },
};
</script>

<style scoped>
img {
  height: 44px;
  border-radius: 6px;
}

.el-card:hover {
  background-color: #80ffcc;
  cursor: pointer;
  color: blue;
}
</style>
