<template>
  <div class="alert-box" style="text-align: left">
    <el-divider content-position="center"> 告警消息区域 </el-divider>
    <el-alert
      :title="level"
      type="error"
      effect="dark"
      :closable="false"
      style="width: 6rem"
      center
    />
    <div class="details_error" style="font-size: 14px">
      <p style="font-weight: 600">
        {{ title }}
      </p>
      <p>{{ content }}</p>
      <span>磁盘分区挂载点: /var/log</span>
      <el-progress
        :text-inside="true"
        :stroke-width="22"
        :percentage="90"
        status="exception"
      />
    </div>
    <ElDivider />
    <!-- <el-alert
      title="重要告警"
      type="warning"
      effect="dark"
      :closable="false"
      style="width: 6rem"
      center
    /> -->
    <!-- <div class="details_warning" style="font-size: 15px">
      <p style="font-weight: 600">
        磁盘分区/var/log/的利用率达到80%，请扩容或清理空间。
      </p>
      <p>告警关联信息如下,</p>
      <p>主机: 172.16.0.3</p>
      <p>运维助手已为您创建ssh连接，并登录成功，请处理。</p>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "Hello, Vue!",
      closable: "false",
    };
  },
  props: ["level", "title", "content"],
};
</script>
<style scoped>
.el-alert {
  --el-alert-title-font-size: 14px;
  animation-name: flashlight;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
}
.alarm-error {
  background-color: #ffaa33;
}
.alarm-warning {
  background-color: #f02828;
}

.details_error {
  background-color: #fab8b8;
  text-align: left;
  padding: 0.6rem;
  border: 2px solid #f35959;
}
.details_warning {
  background-color: #ffdfb3;
  text-align: left;
  padding: 0.6rem;
  border: 2px solid #ffbf66;
}
.el-progress {
  max-width: 300px;
  margin: 0.28rem;
}

@keyframes flashlight {
  0% {
  }
  100% {
    background-color: #8e24aa  ;
  }
}
</style>
