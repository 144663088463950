<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="200px">
        <p class="subtitle">SRE 运维练兵场</p>
        <el-image
          src="/topics/sre_logo_trans.png"
          alt="SRE azlinux site logo"
          style="width: 78px; height: 78px"
        ></el-image>
      </el-aside>
      <el-main>
        <el-collapse>
          <el-collapse-item
            title="问题：网站定位，面向的用户群体?"
            style="padding: 0.32rem"
          >
            <p style="font-weight: 600">回复:</p>
            <p>主要是服务于IT行业的运维人员，系统管理员类型的工程师。</p>
            <p>
              构建理论+实践型运维人才。 SRE = 高可用系统管理能力 + 软件工程能力
            </p>
            <p>
              网站精心设计了实战场景，收集了大厂中厂的运维典型案例，并做成了交互式实验模型。
            </p>
            <p>
              通过完成这些生产级别的运维演练，您的系统管理/运维技能一定会得到提高。
            </p>
          </el-collapse-item>
          <el-collapse-item
            title="问题：网站所有的资源都免费使用吗？"
            style="padding: 0.32rem"
          >
            <p style="font-weight: 600">回复:</p>
            <p>部分严重消耗云计算资源的练习项目需要收费。</p>
            <p>
              收费的练习项目会有【<span style="color: red">vip</span
              >】字样的标注。
            </p>
            <p>
              例如，您在练习zookeeper节点的扩容缩容时候，
              由3个节点无损热扩容为5个节点时.
            </p>
            <p>
              需要耗费一些计算资源(可能是1核心2GB内存配置的虚拟机，可能是容器)
              对于这样的练习，本网站适当收取一些费用。
            </p>
          </el-collapse-item>

          <el-collapse-item
            title="问题：账号是否可以共享给其他人使用?"
            style="padding: 0.32rem"
          >
            <p style="font-weight: 600">回复:</p>
            <p>共享账号会带来资源争抢，产生异常！</p>
            <p>
              一个账号某时刻只能绑定一个运行中的资源，如果同时登录，那么会产生资源中断，影响您当前的正常使用。
            </p>
            <p>本网站会员费用价格不高，短期使用可购买月度/季度会员。</p>
          </el-collapse-item>

          <el-collapse-item
            title="问题：能不能送我免费的VIP会员?"
            style="padding: 0.32rem"
          >
            <p style="font-weight: 600">回复:</p>
            <p>如果你认识站长的话，那么就可以获赠月度VIP会员。</p>
          </el-collapse-item>

          <el-collapse-item
            title="问题：学完全部内容需要多久?"
            style="padding: 0.32rem"
          >
            <p style="font-weight: 600">回复:</p>
            <p>可能需要持续学习1年以上，甚至2年等。</p>
            <p>不建议地毯式学习。建议您挑选您可能工作中用到的技术进行学习。</p>
          </el-collapse-item>

          <el-collapse-item
            title="问题：我有疑问，是否能提供答疑服务，提供咨询服务？"
            style="padding: 0.32rem"
          >
            <p style="font-weight: 600">回复:</p>
            <p>
              您可以到【运维问答】板块免费发表您的疑问，站长看到问题后，有时间会回答。其他用户也可以回答。
            </p>
            <p>较复杂的问题，目前精力有限，暂不提供答疑服务。</p>
          </el-collapse-item>

          <el-collapse-item title="问题：其他问题?" style="padding: 0.32rem">
            <p style="font-weight: 600">回复:</p>
            <p>
              您可以发送您的问题/咨询/建议等到邮箱 azlinux@azlinux.com
              ，我们收到信息后会尽快回复。
            </p>
            <p>谢谢您的支持。</p>
          </el-collapse-item>
        </el-collapse>
      </el-main>
    </el-container>
  </div>
</template>

<script></script>
<style scoped>
.subtitle {
  margin-top: 4rem;
  margin-bottom: 2rem;
  text-align: center;
}
p {
  text-align: left;
  padding: 0 0.2rem;
}
</style>
