<template>
  <el-container>
    <el-button type="primary" @click="connectBox">SSH连接环境</el-button>
    <div class="termtitle">
      <el-tooltip content="全屏新会话" placement="left-start">
        <span class="indicator" @click="startNewTab">⬤</span>
      </el-tooltip>

      <span id="termname">SSH Terminal</span>
    </div>
    <div class="realiframe">
      <iframe :src="labos"> </iframe>
    </div>
  </el-container>
</template>

<script>
export default {
  name: "BigTerms",
  data() {
    return {
      labos: "",
    };
  },
  props: ["thost", "whost", "wport", "port", "lname", "passwd"],
  methods: {
    connectBox() {
      this.labos = this.compose_ssh_string();
      console.log(this.labos);
    },
    compose_ssh_string() {
      return (this.labos =
        "http://" +
        this.whost +
        ":" +
        this.wport +
        "/?hostname=" +
        this.thost +
        "&username=" +
        this.lname +
        "&port=" +
        this.port +
        "&password=" +
        this.passwd);
    },
    startNewTab() {
      window.open(this.labos, "_blank");
    },
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.el-button {
  margin: 4px 12px;
}
.connect {
  margin: 12px 0;
  align-items: left;
  display: flex;
  justify-content: flex-start;
}
iframe {
  height: 600px;
  width: 800px;
  border: 2px solid #263238;
  box-sizing: border-box;
}
.termtitle {
  background-color: #263238;
  height: 1.48rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 800px;
  text-align: left;
}
.indicator {
  color: #00e676;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

#termname {
  color: white;
  font-size: 14px;
}
</style>
