<template>
  <div>
    <CopyToClipboard />
  </div>
</template>

<script>
import CopyToClipboard from "../views/base/CopyToClipboard.vue";

export default {
  components: {
    CopyToClipboard,
  },
};
</script>
