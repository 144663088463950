<template>
  <div>
    <el-footer class="footer" @click="toggleVisibility" v-show="isVisible">
      <div class="container">
        <p class="text">
          <span>运维练兵场 since2019 【点击隐藏本区域】</span>
        </p>
        <el-link type="info" href="/about-us">关于我们</el-link>
        <el-link type="info" href="/privacy">隐私政策</el-link>
        <el-link type="info" href="/faq">陕ICP备20006263号</el-link>
      </div>
    </el-footer>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  name: "FooterView",
  setup() {
    // 创建一个响应式引用来跟踪div的可见性
    const isVisible = ref(true);

    // 定义一个方法来切换div的可见性
    function toggleVisibility() {
      isVisible.value = !isVisible.value;
    }

    // 返回模板中使用的数据和方法
    return {
      isVisible,
      toggleVisibility,
    };
  },
};
</script>

<style scoped>
p {
  text-align: center;
}
.footer {
  min-height: 4rem;
  font-size: 14px;
  padding-top: 0.1rem;
  color: gray;
  background-color: var(--el-color-info-light-9);
  width: 100%;
  transition: height 0.3s ease;
}

.el-link {
  margin-right: 1rem;
  margin-bottom: 0.2rem;
}
</style>
