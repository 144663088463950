<template>
  <el-row
    :gutter="20"
    class="terminology-item"
    v-for="(t, index) in terms"
    :key="index"
  >
    <el-col :span="4">
      <h3>{{ t.term }}</h3>
    </el-col>
    <el-col :span="10">
      <p>
        {{ t.termExplain }}
      </p>
    </el-col>
    <el-col :span="10">
      <p>
        {{ t.termInstance }}
      </p>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      terms: [
        {
          term: "术语",
          termExplain: "解释",
          termInstance: "对话示例",
        },
        {
          term: "AZ",
          termExplain:
            "Availability Zone（可用区），云计算中关键的概念，指在同一地域（Region）内，电力和网络互相独立的物理区域，可用区之间通过高速网络连接。",
          termInstance: "小张，你帮忙在北京Region中的az1, az2创建2个虚拟机。",
        },
        {
          term: "ACL",
          termExplain: "(access control list)，访问控制列表",
          termInstance: "3I started a KVM machine.",
        },
        {
          term: "AK/SK",
          termExplain: "(access key)/(secret key)",
          termInstance: "4I started a KVM machine.",
        },
        {
          term: "Active Directory¶",
          termExplain: "(access key)/(secret key)",
          termInstance: "4I started a KVM machine.",
        },
        {
          term: "allocate",
          termExplain: "(access key)/(secret key)",
          termInstance: "4I started a KVM machine.",
        },
        {
          term: "ARP",
          termExplain: "(Address Resolution Protocol)",
          termInstance: "4I started a KVM machine.",
        },
        {
          term: "Apache",
          termExplain: "(access key)/(secret key)",
          termInstance: "4I started a KVM machine.",
        },
        {
          term: "AWS",
          termExplain: "",
          termInstance: "",
        },
        {
          term: "",
          termExplain: "",
          termInstance: "",
        },
        {
          term: "",
          termExplain: "",
          termInstance: "",
        },
        {
          term: "",
          termExplain: "",
          termInstance: "",
        },
        {
          term: "",
          termExplain: "",
          termInstance: "",
        },
        {
          term: "",
          termExplain: "",
          termInstance: "",
        },
        {
          term: "",
          termExplain: "",
          termInstance: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.terminology-item {
  padding: 0 4rem;
  font-size: 14px;
}
p {
  text-align: left;
}

.letter-nav {
  margin: 1rem 6rem;
}

.el-col {
  border-bottom: 1px solid coral;
}

.el-button {
  border-bottom: 2px solid burlywood;
}
</style>
