<template>
  <div
    class="knowledge-card-wrapper"
    @click="toggleCard"
    :style="{ height: isCardVisible ? 'auto' : '2.2rem', overflow: 'hidden' }"
  >
    <transition name="fade">
      <div class="knowledge-card" v-show="isCardVisible">
        <div class="blur-layer" v-if="!isCardVisible"></div>
        <h3 class="knowledge-subtitle">{{ subtitle }}</h3>
        <pre class="knowledge-content">{{ content }}</pre>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "KnowledgeCard",
  props: {
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    content: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isCardVisible = ref(false);

    const toggleCard = () => {
      isCardVisible.value = !isCardVisible.value;
    };

    return {
      isCardVisible,
      toggleCard,
    };
  },
};
</script>

<style scoped>
.knowledge-card-wrapper {
  background-color: rgba(180, 255, 180, 0.2);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  height: 2.2rem; /* 初始高度 */
  transition: height 0.8s ease; /* 过渡效果 */
}

.knowledge-card-wrapper:hover {
  background-color: #eee ;
  transition: background-color 1.4s;
}

.knowledge-card {
  text-align: left;
  padding: 20px;
  background-color: #eee;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  position: relative;
  z-index: 1; /* 确保在blur-layer之上 */
}

.blur-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* 毛玻璃背景色 */
  z-index: 0; /* 在knowledge-card之下 */
}

.knowledge-subtitle {
  text-align: left;
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #666;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
