<template>
  <div class="portal">
    <el-row gutter="20">
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>变更管理系统</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              对生产环境的修改必须经过此系统，统一管理和记录了对环境的操作。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'change.azlinux.com'"
              >change.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard" style="background-color: #f3e5f5">
          <el-text class="etitle">
            <h4>堡垒机</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>授权用户访问企业生产环境，审计用户操作行为。</p>
          </div>
          <div class="center-content">
            <el-link :href="'bastion.azlinux.com'"
              >bastion.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard" style="background-color: #ffe0b2">
          <el-text class="etitle">
            <h4>告警中心</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              收集来自各个监控工具的告警信息，对告警发出通知，例如邮件，短信，电话。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >alarm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard" style="background-color: #fafafa ">
          <el-text class="etitle">
            <h4>ITSM</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              为运维业务流程的标准化服务，如发起运维请求，运维主管审批运维请求等。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'" 
              >itsm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row gutter="20">
      <el-col :span="6">
        <el-card class="linkcard" style="background-color: #e8f5e9  ">
          <el-text class="etitle">
            <h4>CMDB</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              CMDB是一种配置管理数据库，用于记录和管理IT资产，例如服务器硬件信息，网络IP信息等。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >cmdb.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>日志中心</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>对于接入了日志中心的应用，提供快速日志查询。</p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >monitoring.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>运维日历</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              对公司的运维活动记录和规划，例如每周三例行上线，也记录运维人员的在岗值班状态。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >alarm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>ITSM</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              为运维业务流程的标准化服务，如发起运维请求，运维主管审批运维请求等。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >itsm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row gutter="20">
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>变更系统</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              CMDB是一种配置管理数据库，用于记录和管理IT资产，例如服务器硬件信息，网络IP信息等。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >cmdb.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>监控中心</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>收集、整合和分析各种监控数据。</p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >monitoring.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>告警中心</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              收集来自各个监控工具的告警信息，对告警发出通知，例如邮件，短信，电话。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >alarm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>ITSM</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              为运维业务流程的标准化服务，如发起运维请求，运维主管审批运维请求等。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >itsm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row gutter="20">
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>CMDB</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              CMDB是一种配置管理数据库，用于记录和管理IT资产，例如服务器硬件信息，网络IP信息等。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >cmdb.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>监控中心</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>收集、整合和分析各种监控数据。</p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >monitoring.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>告警中心</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              收集来自各个监控工具的告警信息，对告警发出通知，例如邮件，短信，电话。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >alarm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="linkcard">
          <el-text class="etitle">
            <h4>ITSM</h4>
          </el-text>
          <div class="center-content">
            <el-divider @click="getintro" content-position="right"
              ><el-link type="info">简介 &gt;&gt;</el-link></el-divider
            >
          </div>
          <div v-show="show" class="introduction-content">
            <p>
              为运维业务流程的标准化服务，如发起运维请求，运维主管审批运维请求等。
            </p>
          </div>
          <div class="center-content">
            <el-link :href="'cmdb.azlinux.com'"
              >itsm.azlinux.com</el-link
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    getintro(event) {
      console.log(event.target)
      let nextElement = event.target.nextElementSibling;
      console.log(nextElement)
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
.portal {
  margin: 1.2rem 4.8rem;
}
.linkcard {
  max-width: 280px;
  margin-bottom: 1rem;
  background-color: lightgoldenrodyellow;
}
.el-card:hover {
  box-shadow: 4px 6px seagreen;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: left;
}

.introduction-content {
  font-size: 14px;
  text-align: left;
}
.el-card h4 {
  font-size: 16px;
  margin: 0.4rem 0;
}
</style>
