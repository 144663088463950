<template>
  <div>
    <h4>运维工程师的运维环境编程技能（练习模式: 任务驱动）</h4>
    <el-row>
      <el-col :span="6">
        <el-card class="card-item" @click="handleCardClick('btn1')">
          <div class="clearfix">
            <span
              >Bash<el-icon><StarFilled color="#00e676" /></el-icon
            ></span>
          </div>
          <el-divider></el-divider>
          <el-text v-if="currentCard === 'btn1'">
            Bash是运维工程师使用频次最高的编程语言，尤其用于系统任务管理。（必备）
          </el-text>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="card-item" @click="handleCardClick('btn2')">
          <div class="clearfix">
            <span
              >Python3<el-icon><StarFilled color="#00e676" /></el-icon
            ></span>
          </div>
          <el-divider></el-divider>
          <el-text v-if="currentCard === 'btn2'">
            运维工程师必备的编程语言，功能比Bash强大许多，复杂任务需要Python3来完成。
            （必备）
          </el-text>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="card-item" @click="handleCardClick('btn3')">
          <div class="clearfix">
            <span>Golang</span>
          </div>
          <el-divider></el-divider>

          <el-text v-if="currentCard === 'btn3'">
            Golang生态已成熟，且支持依赖打包（静态编译），如有额外时间，可以掌握它。(可选)
          </el-text>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="card-item" @click="handleCardClick('btn4')">
          <div class="clearfix">
            <span>C Language</span>
          </div>
          <el-divider></el-divider>

          <el-text v-if="currentCard === 'btn4'">
            较接近系统底层的语言，功能强大，涉及到内核等高级功能需要用到它，如有额外时间，可以掌握它。(可选)
          </el-text>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { StarFilled } from "@element-plus/icons-vue";
export default {
  data() {
    return {
      currentCard: "btn1",
    };
  },
  components: {
    StarFilled,
  },
  methods: {
    handleCardClick(title) {
      this.currentCard = title;
    },
  },
};
</script>

<style scoped>
.card-item {
  cursor: pointer;
}

.el-card {
  margin-right: 4rem;
}
.el-row {
  margin-left: 4rem;
  margin-right: 4rem;
}
</style>
