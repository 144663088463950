<template>
  <AlarmBox :level="level" :title="title" :content="content" />
  <RateBox />
  <BigTerms
    :whost="whost"
    :thost="thost"
    :wport="wport"
    :port="port"
    :lname="lname"
    :passwd="passwd"
  />
</template>

<script>
import AlarmBox from "../../base/AlarmBox.vue";
import RateBox from "../../base/RateBox.vue";
import BigTerms from "../../base/BigTerms.vue";

export default {
  name: "CmdLs",
  components: {
    AlarmBox,
    RateBox,
    BigTerms
  },
  data() {
    return {
        whost: "localhost",
        thost: "192.168.56.102",
        wport: 8858,
        port: 3022,
        lname: "sre",
        passwd: "MTIzMTIz",
        level: "紧急告警",
        title: "磁盘分区/var/log/的利用率达到91%，请扩容或清理空间。",
        content:
          " 告警关联信息如下: 主机: 172.16.0.3。 运维助手已为您创建ssh连接，并登录成功，请尽快处理。",
  };
},
};
</script>
