<template>
  <div class="user-center">
    <div class="section mynews">
      <h3>我的消息</h3>
      <div v-if="messages.length">
        <el-alert
          :closable="false"
          type="error"
          v-for="message in messages"
          :key="message.id"
        >
          {{ message.content }}
        </el-alert>
      </div>
      <div v-else>暂无消息</div>
    </div>
    <div class="section profile">
      <h3>个人资料</h3>
      <el-form ref="profileForm" :model="profile" label-width="120px">
        <el-form-item label="用户名">
          <el-input v-model="profile.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="运维昵称">
          <el-input v-model="profile.nickname"></el-input>
        </el-form-item>
        <el-form-item label="电子邮件">
          <el-input v-model="profile.email" disabled></el-input>
        </el-form-item>
        <el-form-item label="设置新密码">
          <el-input v-model="profile.password" type="password"></el-input>
        </el-form-item>
        <el-button type="primary" @click="updateProfile">保存</el-button>
      </el-form>
    </div>

    <div class="section">
      <h3>VIP会员状态</h3>
      <p>当前状态: {{ vipStatus.active }}</p>
      <p>起始时间: {{ vipStatus.startTime }}</p>
      <p>结束时间: {{ vipStatus.endTime }}</p>
      <p>剩余时长: {{ vipStatus.remainingTime }}</p>
      <el-button type="primary" @click="buyVip">购买VIP</el-button>
    </div>

    <div class="section">
      <h3>云资源倾向</h3>
      <el-row>
        <el-radio-group v-model="cloudprovider" @change="setCloudProvider">
          <el-radio label="auto">自动选择</el-radio>
          <el-radio label="alibaba">阿里云</el-radio>
          <el-radio label="huawei">华为云</el-radio>
          <el-radio label="aws">AWS</el-radio>
          <el-radio label="azure">Azure</el-radio>
          <el-radio label="gcp">GCP谷歌云</el-radio>
          <el-radio label="tencent">腾讯云</el-radio>
        </el-radio-group></el-row
      >
      <el-button type="primary" @click="saveCloudProvider"
        >保存云资源倾向</el-button
      >
    </div>

    <div class="section">
      <h3>主题设置</h3>
      <el-row>
        <el-radio-group v-model="theme" @change="changeTheme">
          <el-radio label="light">沉稳灰色</el-radio>
          <el-radio label="red">热情红</el-radio>
          <el-radio label="green">青草绿</el-radio>
          <el-radio label="blue">天空蓝</el-radio>
          <el-radio label="colorful">混合色</el-radio>
        </el-radio-group></el-row
      >
      <el-button type="primary" @click="saveTheme">保存主题</el-button>
    </div>

    <div class="section">
      <h3>帮助与支持</h3>
      <p>当前为公测阶段</p>
      <p>联系网站维护人员: email: azhongweiwen@sina.com</p>
    </div>

    <el-divider></el-divider>
    <div class="section">
      <el-button type="danger" @click="logout">退出登录</el-button>
    </div>

    <el-divider></el-divider>

    <div class="section">
      <h3>危险操作区域</h3>
      <el-button type="info" @click="logout">删除账户</el-button>
      <p>从网站彻底删除您的账户，清理您所有的数据。</p>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  name: "UserCenter",
  data() {
    return {
      value: ref(0),
      cloudprovider: ref(0),
      profile: {
        username: "username",
        nickname: "",
        email: "example@example.com",
        password: "",
      },
      vipStatus: {
        active: "生效中",
        startTime: "2024-01-01",
        endTime: "2024-05-01",
        remainingTime: "29天",
      },
      theme: "light",
      messages: [],
    };
  },
  created() {
    this.messages = [
      {
        id: 1,
        content:
          "系统检测到，您存在恶意使用网站资源的行为，已封锁ip。72小时后自动解锁。",
      },
    ];
  },
  methods: {
    updateProfile() {
      console.log("Updating profile...");
    },
    buyVip() {
      console.log("Buying VIP...");
    },
    changeTheme(value) {
      console.log("Changing theme to", value);
    },
    logout() {
      console.log("Logging out...");
    },
    deleteAccount() {
      console.log("Delete account and user data...");
    },
    saveTheme() {
      console.log("Saving theme.");
    },
    setCloudProvider(cloudprovider) {
      console.log("Set cloud provider to", cloudprovider);
    },
  },
};
</script>
<style scoped>
p {
  text-align: left;
  font-size: 14px;
}

.el-alert {
  font-weight: 600;
  font-size: 17px;
}
.user-center {
  margin: 2rem 6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 600px;
  background-color: #f1f1f1;
}
.profile {
  max-width: 600px;
}
h3 {
  text-align: left;
}
.section {
  margin-bottom: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 600px;
}
.el-button {
  margin-top: 1rem;
}
</style>
