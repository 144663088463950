<template>
  <div>
    <h4>选择在线环境的连接选项</h4>
    <el-text>运行方式:</el-text>
    <el-radio-group v-model="runMode">
      <el-radio-button label="容器" value="容器" />
      <el-badge value="vip" class="item">
        <el-radio-button label="虚拟机" value="虚拟机" />
      </el-badge>
      <el-badge value="vip" class="item">
        <el-radio-button label="物理机" value="物理机" disabled />
      </el-badge>
    </el-radio-group>
    <br />
    <el-text>发行版:</el-text>
    <el-radio-group v-model="osDistro">
      <el-radio-button label="Ubuntu" value="Ubuntu" />
      <el-radio-button label="CentOS" value="CentOS" />
      <el-radio-button label="Alpine" value="Alpine" />
      <el-radio-button label="Debian" value="Debian" />
      <el-radio-button label="FreeBSD" value="FreeBSD" />
      <el-radio-button label="Fedora" value="Fedora" />
      <el-radio-button label="RHEL" value="RHEL" />
      <el-radio-button label="OpenSUSE" value="OpenSUSE" disabled />
    </el-radio-group>
    <br />
    <el-text>架构:</el-text>
    <el-radio-group v-model="osArch">
      <el-radio-button label="x86" value="x86" />
      <el-radio-button label="aarch64" value="aarch64" disabled />
    </el-radio-group>
    <br />
    <el-text>账户:</el-text>
    <el-radio-group v-model="accountType">
      <el-radio-button label="sre" value="sre" />
      <el-radio-button label="root" value="root" />
    </el-radio-group>
    <br />
    <el-text>超时重置时间:</el-text>
    <el-radio-group v-model="tmout">
      <el-radio-button label="15min" value="15min" />
      <el-radio-button label="24hour" value="24hour" disabled />
    </el-radio-group>
    <br />
    <el-text>网络模式:</el-text>
    <el-radio-group v-model="netMode">
      <el-radio-button label="noNet" value="无网络" />
      <el-radio-button label="nat" value="nat网络" disabled />
      <el-radio-button label="public" value="公网ip" disabled />
    </el-radio-group>
    <br />
    <el-divider></el-divider>
    <el-button type="primary" id="sshcon" @click="ConnectSSH"
      >SSH环境准备</el-button
    >

  </div>
  <BigTerms
    :whost="whost"
    :thost="thost"
    :wport="wport"
    :port="port"
    :lname="lname"
    :passwd="passwd"
  />
</template>
<script>
import axios from "axios";
import BigTerms from "./base/BigTermsNoTitle.vue";

export default {
  data() {
    return {
      runMode: "容器",
      osArch: "x86",
      accountType: "sre",
      tmout: "600",
      osDistro: "Ubuntu",
      netMode: "noNet",
      whost: "localhost",
      wport: 8858,
      thost: "192.168.56.102",
      port: 3022,
      lname: "sre",
      passwd: "azlinux.com",
      labos: "",
    };
  },
  methods: {
    async getLimitedOSList() {
      const response = await axios.get(
        "http://sre.azlinux.com:5011/api/online-distros"
      );
      return response.data;
    },
    async ConnectSSH() {
      try {
        const resp = await this.getLimitedOSList();
        this.thost = resp[0].hostname;
        this.thost = "sre.azlinux.com";
        this.port = resp[0].port;
        this.lname = resp[0].user;
        this.passwd = resp[0].pass;
        this.passwd = "YXpMaW51eC5jb20zCg==";
        this.labos = this.compose_ssh_string();
        console.log(this.labos);
      } catch (error) {
        console.error("Fetch data error", error);
      }
    },
    compose_ssh_string() {
      return (this.labos =
        "http://" +
        this.whost +
        ":" +
        this.wport +
        "/?hostname=" +
        this.thost +
        "&username=" +
        this.lname +
        "&port=" +
        this.port +
        "&password=" +
        this.passwd);
    },
  },
  components: {
    BigTerms,
  },
};
</script>
<style scoped>
.el-radio-group {
  margin-bottom: 0.6rem;
}

h4 {
  margin: 0.6rem;
}

.el-text,
.el-icon {
  margin-right: 10px;
}

#sshcon {
  margin-bottom: 1rem;
}
</style>
