<template>
  <div id="reg">
    <RollingBall />
    <h3>用户注册</h3>
    <el-form :model="registerForm" label-width="80px" class="register-form">
      <el-form-item label="Email" prop="email">
        <el-input v-model="registerForm.email"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="registerForm.password"></el-input>
      </el-form-item>
      <el-form-item label="密码确认" prop="confirmPassword">
        <el-input
          type="password"
          v-model="registerForm.confirmPassword"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-text type="danger"
          >注册须知: 本网站服务器资源不可用于【运维练习】以外的用途！</el-text
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="register">同意并注册</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div id="backend-data"></div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import RollingBall from "./base/RollingBall.vue";

export default {
  components: {
    RollingBall,
  },
  setup() {
    const registerForm = ref({
      email: "",
      password: "",
      confirmPassword: "",
    });
    const register = () => {
      if (!registerForm.value.email) {
        ElMessage.error({
          message: "邮箱地址不可以为空。",
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.indexOf("@") == -1) {
        ElMessage.error({
          message: "邮箱地址缺少必要的at符号。",
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.indexOf(".") == -1) {
        ElMessage.error({
          message: "邮箱地址缺少必要的dot符号。",
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.length > 40) {
        ElMessage.error({
          message: "邮箱地址整体长度超过了40，请输入合法邮箱。",
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.length < 5) {
        ElMessage.error({
          message: "请输入正确的邮箱地址。",
          offset: 120,
        });
        return;
      }
      if (!registerForm.value.password) {
        ElMessage.error({
          message: "密码不可以为空",
          offset: 120,
        });
        return;
      }

      if (registerForm.value.password.length < 6) {
        ElMessage.error({
          message: "密码长度至少6个字符",
          offset: 120,
        });
        return;
      }
      if (registerForm.value.password.length > 20) {
        ElMessage.error({
          message: "密码长度过长, 不可超过20个字符",
          offset: 120,
        });
        return;
      }

      if (registerForm.value.password !== registerForm.value.confirmPassword) {
        ElMessage.error({
          message: "两次输入的密码不一样!",
          offset: 120,
        });
        return;
      }
      axios
        .post("http://localhost:8094/register", registerForm.value)
        .then((response) => {
          document.getElementById("backend-data").textContent =
            response.data.message + " 自动跳转到登录页面...";

          ElMessage({
            message: "【注册成功】或【账号已存在】, 3秒后跳转到登录页面...",
            type: "success",
            offset: 120,
          });

          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
        })
        .catch((error) => {
          document.getElementById("backend-data").textContent =
            error.data.message;
        });
    };

    return {
      registerForm,
      register,
    };
  },
};
</script>

<style scoped>
#reg {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 0.8rem;
  height: 44vh;
  background-color: #f5f5f5;
}

#backend-data {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #e0f2f1;
  font-family: Arial, sans-serif;
  text-align: center;
}

.register-form {
  width: 300px;
  margin: 10px auto;
}

.el-text {
  font-weight: 600;
  font-size: 13px;
  text-align: left;
}

.el-button {
  padding: 0.32rem 2rem;
}
</style>
