<template>
  <div class="homepage">
    <HomePage slogan="这里有生产级演练环境, 实战中提升运维能力"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/HomePage.vue'

export default {
  name: 'HomeView',
  components: {
    HomePage
  }
}
</script>

<style scoped>
body {
  background-color: grayscale;
}
</style>
