<template>
  <el-row
    :gutter="20"
    class="terminology-item"
    v-for="(t, index) in terms"
    :key="index"
  >
    <el-col :span="4">
      <h3>{{ t.term }}</h3>
    </el-col>
    <el-col :span="10">
      <p>
        {{ t.termExplain }}
      </p>
    </el-col>
    <el-col :span="10">
      <p>
        {{ t.termInstance }}
      </p>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      terms: [
        {
          term: "术语",
          termExplain: "解释",
          termInstance: "对话示例",
        },
        {
          term: "Backup",
          termExplain:
            "（备份）：创建数据的副本，以便在原始数据丢失或损坏时可以恢复。",
          termInstance: "重要的数据要有备份方案，发生意外时候，方便恢复。",
        },
        {
          term: "KVM3",
          termExplain: "3Kernel Virtual Machine",
          termInstance: "3I started a KVM machine.",
        },
        {
          term: "KVM4",
          termExplain: "4Kernel Virtual Machine",
          termInstance: "4I started a KVM machine.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.terminology-item {
  padding: 0 4rem;
  font-size: 14px;
}
p {
  text-align: left;
}

.letter-nav {
  margin: 1rem 6rem;
}

.el-col {
  border-bottom: 1px solid coral;
}

.el-button {
  border-bottom: 2px solid burlywood;
}
</style>
