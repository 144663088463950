<template>
  <div class="ball-container">
    <div class="ball" :class="{ reverse: isReverse }"></div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "RollingBall",
  setup() {
    const isReverse = ref(false);
    const ballAnimation = ref(null);

    return {
      isReverse,
      ballAnimation,
    };
  },
};
</script>

<style scoped>
.ball-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 20px;
}

.ball {
  width: 96px;
  height: 18px;
  background-color: #ff5722;
  position: absolute;
  border-radius: 6px;
  top: 0;
  left: -82px;
  animation-duration: 1.8s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.ball.reverse {
  animation-name: rollToLeft;
}

.ball:not(.reverse) {
  animation-name: rollToRight;
}

@keyframes rollToRight {
  0% {
    left: -96px; /* 小球初始位置在容器左边界外 */
  }
  50% {
    left: calc(100% + 96px); /* 小球结束位置在容器右边界外 */
  }
  100% {
    left: -96px; /* 小球初始位置在容器左边界外 */
  }
}

@keyframes rollToLeft {
  0% {
    left: calc(100% + 96px); /* 小球初始位置在容器右边界外 */
  }
  50% {
    left: -96px; /* 小球结束位置在容器左边界外 */
  }
  100% {
    left: calc(100% + 96px); /* 小球初始位置在容器右边界外 */
  }
}
</style>
