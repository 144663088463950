<template>
  <div class="game-rating">
    <span class="label">本节难度：</span>
    <el-rate
      v-model="difficultyRating"
      :max="5"
      disabled
      void-icon="el-icon-star-off"
      active-icon="el-icon-star-on"
      text-template="{value}颗星"
    ></el-rate>
    <span class="label">技能流行程度：</span>

    <el-rate
      v-model="popularityRating"
      :max="5"
      disabled
      void-icon="el-icon-heart-outline"
      active-icon="el-icon-heart"
      text-template="{value}颗心"
    ></el-rate>
  </div>
</template>

<script>
export default {
  name: "RateBox",
  props: {
    difficulty: {
      type: Number,
      default: 1,
      validator: function (value) {
        return value >= 0 && value <= 5;
      },
    },
    popularity: {
      type: Number,
      default: 3,
      validator: function (value) {
        return value >= 0 && value <= 5;
      },
    },
  },
  data() {
    return {
      difficultyRating: this.difficulty,
      popularityRating: this.popularity,
    };
  },
  watch: {
    difficulty(newVal) {
      this.difficultyRating = newVal;
    },
    popularity(newVal) {
      this.popularityRating = newVal;
    },
  },
};
</script>

<style scoped>
.game-rating {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 2rem;
}
.label {
  font-size: 14px;
}
</style>
