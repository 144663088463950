<template>
  <div id="login">
    <h3>用户登录</h3>
    <el-form
      ref="loginForm"
      :model="loginForm"
      label-width="80px"
      class="login-form"
    >
      <el-form-item label="Email" prop="email">
        <el-input v-model="loginForm.email"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="loginForm.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-link class="forgot-password" type="info" href="/forget-pas"
          >忘记密码?</el-link
        >
        <el-link type="info" href="/register">没有账号? 注册账号</el-link>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login" round>登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  name: "LoginPage",
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      axios
        .post("http://localhost:8094/login", this.loginForm)
        .then((response) => {
          const token = response.data.access_token;
          localStorage.setItem("token", token);
          localStorage.setItem("email", "sre-" + this.email);

          ElMessage.success("登录成功，2秒后跳转到【用户中心】");

          setTimeout(() => {
            window.location.href = "/user-center";
          }, 2000);
        })
        .catch((error) => {
          console.error("Login failed:", error);
          ElMessage.error("登录失败");
        });
    },
  },
};
</script>

<style scoped>
#login {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 0.8rem;
  height: 64vh;
  background-color: #e0e0e0;
  background-image: linear-gradient(to right, #f0f0f0 0%, #d0d0d0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 95%, 50% 100%, 0% 95%);
  text-align: center;
  line-height: 50px;
  color: #333;
}
.login-form {
  width: 300px;
  margin: 10px auto;
}
.el-button {
  padding: 0.32rem 2rem;
  width: 100%;
}
.el-link {
  font-size: 12px;
  padding-left: 1rem;
}
.el-message {
  margin-top: 4rem;
}
</style>
